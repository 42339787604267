import { render } from "react-dom";

import "graphiql/graphiql.min.css";

import GraphiQLWithExtensions from "./Graphiql";

const container = document.getElementById("root");

const defaultQuery = `
query ListCollections {
  collections(sort: {sortKey: CREATED, sortDirection: ASC}) {
    nodes {
      address
      name
      symbol
    }
  }
}
`;

export default function App(): JSX.Element {
  const network = "https://api.zora.co/graphql";
  const graphQLFetcher = (graphQLParams: any) =>
    fetch(network, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(graphQLParams),
      credentials: "omit",
    }).then((response) => response.json());
  return (
    <div id="wrapper">
      <GraphiQLWithExtensions
        fetcher={graphQLFetcher}
        defaultQuery={defaultQuery}
        variables={undefined}
        editorTheme="ayu-mirage"
      ></GraphiQLWithExtensions>
    </div>
  );
}

render(<App />, container);
